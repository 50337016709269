<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ data.data && data.data.name ? data.data.name : "Новый" }} <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model v-model="data" :model="model" :errors="errors" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "@/components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl],

  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      api: "/mechti/information_test",
      model: [
        { name: "status", title: "Статус", type: "select", options: "status_active" },
        { name: "data.name", title: "Название", type: "string", validator: ["req"] },
        { name: "roles_data", title: "Роли", type: "select", options: "roles", chips: true },
        { name: "users_data", title: "Пользователи", type: "select", dir: "user", chips: true, autocomplete: true },
        { name: "information_data", title: "Вопросы", type: "questions" },
      ],
      tests: [],
      needSave1: false,
      errors: {},
      config: {
        type: Object,
        default: () => {
          return { dense: true, hideDetails: "auto" };
        },
      },
    };
  },
  computed: {
    needSave() {
      return JSON.stringify(this.data?.test_data || []) !== JSON.stringify(this.tests);
    },
  },
  watch: {
    "data.test_data": {
      handler(newVal, oldVal) {
        console.log("Изменился объект:", newVal);
        //this.tests = this.data.test_data || [];
      },
      deep: true, // Необходимо указать deep: true, чтобы отслеживать изменения во вложенных свойствах объекта
    },
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
    tests: {
      handler(newVal, oldVal) {
        this.needSave1 = true;
        console.log("tests changed", newVal);
        //this.tests = this.data.test_data || [];
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
